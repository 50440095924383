import React, { useEffect, useState } from 'react';
import { CANCELLATION_OR_REVOCATION_FLOW } from '../../../constants/AdminFlows';
import { useTranslation } from 'react-i18next';
import CustomSelect from '../../../components/inputs/select/CustomSelect';
import { isMobileOnly } from 'react-device-detect';
import {
  generateDays,
  generateYears,
  generateMonths,
} from '../../../constants/Flow';
import moment from 'moment';
import { getToken } from '../../../config/token';
import { Request } from '../../../config/requests';
import { showToast } from '../../../config/helpers';
import { toast} from 'react-toastify';
import Loading from '../../../components/common/Loading';


const CancellationOrRevocation = ({ setIsLoading, isLoading, handleClose, setIsDone }) => {
  const { t } = useTranslation();
  const token = getToken();
  const [selectedItem, setSelectedItem] = useState(null);
  const [date, setDate] = useState({ year: null, month: null, day: null });
  const [newEmail, setNewEmail] = useState(null);
  const [petName, setPetName] = useState(null);
  const [policyNo, setPolicyNo] = useState(null);
  const [claimNo, setClaimNo] = useState(null);
  const [policy, setPolicy] = useState({});
  const [selectedPet, setSelectedPet] = useState(null);
  const [terminationDate, setTerminationDate] = useState(0.00)
  const [errorMessage, setErrorMessage] = useState(null)
  const [extProcess, setExtProcess] = useState(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [chipError, setChipError] = useState(false);
  const [tariff, setTariff] = useState(null);
  const [premiumAmount, setPremiumAmount] = React.useState(0.0);
  const [selectedCoverage, setSelectedCoverage] = useState(null);
  const [paymentFrequency, setPaymentFrequency] = React.useState(null);
  const [payMonthFrequency, setPayMonthFrequency] = useState(null);
  const [claimsNo, setClaimsNo] = useState(null)
  const [petChip, setPetChip] = useState('');
  const [years, setYears] = useState({
    label: moment().format('yy'),
    value: moment().format('yy'),
  });
  const [months, setMonths] = useState({
    label: moment().format('MM'),
    value: moment().format('MM'),
  });
  const [days, setDays] = useState({
    label: moment().format('DD'),
    value: moment().format('DD'),
  });
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isUpdatingData, setIsUpdatingData] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);


  const getTerminationDate = React.useMemo(() => {
    switch (selectedItem?.value) {
      case "revocation":
        return moment(policy.dateInception, 'DD-MM-YYYY').format('DD.MM.YYYY');
      case "ordinary_cancelation":
        return moment(policy.dateInception).isSameOrAfter(moment().add(1, 'year')) ?
          policy.dateExpiry :
          moment(`${days.value}-${months.value}-${years.value}`, 'DD-MM-YYYY').add(30, 'days').format('DD.MM.YYYY');
      case "cancellation_for_other_reasons":
        return `${days.value}.${months.value}.${years.value}`
      case "termination_claim_insurer":
        return `${days.value}.${months.value}.${years.value}`
      case "termination_claim_customer":
        return `${days.value}.${months.value}.${years.value}`
      case "ordinary_cancelation_insurer":
        return `${days.value}.${months.value}.${years.value}`
      case "withdrawal_by_the_insurer":
        return `${days.value}.${months.value}.${years.value}`
      default:
        break;
    }
  }, [selectedItem]);

  const findContract = async () => {
    setIsLoadingData(true);
    let mainUrl = `api/policy/${policyNo}`;
    let res = await Request({
      method: 'get',
      route: mainUrl,
      token: token,
    });
    res = await res.json();
    setPolicy(res);
    findPet(res.policy_id,);
  };

  const findPet = async (policyId) => {
    let mainUrl = `api/users/v2/pet?policy_id=${policyId}`;
    await Request({
      method: 'get',
      route: mainUrl,
      token: token,
    })
      .then((res) => res.json())
      .then((data) => {
        setSelectedPet(data.data)
        setTariff(data.products.petInsurance.data.selectedPackage);
        setSelectedCoverage(data.products.petInsurance.data.selectedCoverage);
        setPayMonthFrequency(data.quote.schedules.payMonthFrequency);

        calculatePremiumAmount(
          policyId,
          data.products.petInsurance.data.selectedPackage,
          data.products.petInsurance.data.selectedCoverage,
          data.quote.schedules.payMonthFrequency
        );
      })
      .finally(() => {
        setIsLoadingData(false);
      });
  };

  const terminateContract = async () => {
    setIsUpdatingData(true)
    let mainUrl = 'api/policy/cancel_contract'
    await Request({
      method: 'post',
      route: mainUrl,
      token: token,
      values: {
        type: String(selectedItem.value).toLocaleLowerCase(),
        policyId: policy.policy_id,
        cancelDate: `${days.value}.${months.value}.${years.value}`,
        claimsNo: claimsNo || undefined
      }
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('updateContract failed')
        }
        setIsCompleted(true);
        return res.json();
      })
      .then((data) => {
        setIsDone(true);
      })
      .catch((e) => {
        if (!toast.isActive('error_toast')) {
        showToast(t('somethingWentWrong'), { error: true });
        }
      })
      .finally(() => {
        setIsUpdatingData(false)
      });
  }

  const confirmPet = React.useMemo(() => {
    return selectedPet ? selectedPet.nameOfPet + ", " + tariff + " " + (selectedCoverage * 100) + "%, " + premiumAmount + "€" : ""
  }, [selectedPet, tariff, premiumAmount])

  const calculatePremiumAmount = async (policyId, t, cov, frequency) => {
    let coverage;
    switch (String(selectedCoverage)) {
      case '0.8':
        coverage = '80%';
        break;
      case '0.7':
        coverage = '70%';
        break;
      case '0':
        coverage = '100%';
        break;
      default:
        break;
    }
    let mainUrl = `api/policy/calculate/${policyId}`;
    await Request({
      method: 'GET',
      route: mainUrl,
      token: token,
    })
      .then((res) => res.json())
      .then((data) => {
        let coverage;
        switch (String(selectedCoverage)) {
          case '0':
            coverage = 1;
            break;
          default:
            coverage = selectedCoverage;
            break;
        }
        switch (frequency) {
          case 1:
            setPaymentFrequency('month');
            setPremiumAmount(
              (
                data[
                  String(t).split(' ')[0].toLocaleLowerCase() +
                  String(t).split(' ')[1]
                ].pricing[cov] / 12
              ).toFixed(2)
            );
            break;
          case 3:
            setPaymentFrequency('quarter');
            setPremiumAmount(
              (
                data[
                  String(t).split(' ')[0].toLocaleLowerCase() +
                  String(t).split(' ')[1]
                ].pricing[cov] / 4
              ).toFixed(2)
            );
            break;
          case 6:
            setPaymentFrequency('halfYear');
            setPremiumAmount(
              (
                data[
                  String(t).split(' ')[0].toLocaleLowerCase() +
                  String(t).split(' ')[1]
                ].pricing[cov] / 2
              ).toFixed(2)
            );
            break;
          case 12:
            setPaymentFrequency('year');
            setPremiumAmount(
              (
                data[
                  String(t).split(' ')[0].toLocaleLowerCase() +
                  String(t).split(' ')[1]
                ].pricing[cov] / 1
              ).toFixed(2)
            );
            break;
          default:
            break;
        }
      });
  };

  useEffect(() => {
    setDays({
      label: moment().add(1, 'days').format('DD'),
      value: moment().add(1, 'days').format('DD'),
    });
  }, [months]);

  const monthsOptions = React.useMemo(() => {
    return generateMonths(
      years.label,
      moment().add(10, 'year').format('YYYY-MM-DD')
    );
  }, [years.value]);


  useEffect(() => {
    setMonths(
      monthsOptions.some((el) => el.value == months.value)
        ? months
        : monthsOptions[monthsOptions.length - 1]
    );
  }, [monthsOptions]);

  if (isCompleted) {
    return (
      <>
        <div
          className="modal_container__body__text"
          style={{
            maxWidth: '500px',
            margin: '40px 0',
            textAlign: 'start',
          }}
        >
          {t('dataChangedSuccess')}
        </div>

        <div className="modal_container__body__row-modal">
          <div
            className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
            onClick={handleClose}
          >
            OK
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="w-100 px-5 d-flex gap-4 flex-column h-auto ">
      {
        CANCELLATION_OR_REVOCATION_FLOW
          // .filter(el => ((policy.policy_id && el?.formType != "search") || el?.formType == "search") ? el : undefined)
          .map((section, index) => {
            return (
              <>
                {(section.formType === 'search' ||
                  (section.formType === 'result' && policy.policy_id))
                  ? (
                    <div key={`section-${index}`} className="section-container w-100">
                      {(section.formType === 'result' && isLoadingData)
                        ? (
                          <Loading noText fast width={'100%'} scroll={false} />
                        ) : (
                          <>
                            {Object.entries(section.components).map(
                              ([key, step], stepIndex) => {
                                // Check if step is an object with a type property
                                if (typeof step === 'object' && step.type) {
                                  if (
                                    (step.type === 'text_input' && !step.dependency && !step.dependencies) ||
                                    (step.type === 'text_input' && step.dependency &&
                                      step.dependency == selectedItem?.value) ||
                                    (step.type === 'text_input' && step.dependencies && step.dependencies.includes(selectedItem?.value))
                                  ) {
                                    return (
                                      <div
                                        key={`text-step-${stepIndex}`}
                                        className="modal_container__body__inputs align-items-start"
                                      >
                                        <label htmlFor={step.id}>{t(step.text)}</label>
                                        <input
                                          id={step.id}
                                          className="modal_container__body__input w-100"
                                          placeholder={t(step.placeholder)}
                                          required={step.required || false}
                                          disabled={step.disabled || false}
                                          onChange={(e) =>
                                            (section.formType == 'search' &&
                                              !step.disabled) ?
                                              step?.dependencies ? setClaimsNo(e.target.value) : setPolicyNo(e.target.value) : null

                                          }
                                          value={
                                            section.formType == 'search' && !step.disabled ? policyNo : !step?.dependencies ? confirmPet : claimsNo
                                          }
                                        />
                                      </div>
                                    );
                                  }

                                  if (step.type === 'button') {
                                    return (
                                      <div
                                        key={`button-step-${stepIndex}`}
                                        className="modal_container__body__inputs align-items-start"
                                      >
                                        <button
                                          id={step.id}
                                          onClick={() => {
                                            section.formType == 'search' && findContract();
                                          }}
                                          className={`button_red_small__admin ${step.btnType || ''
                                            }`}
                                          disabled={policyNo?.length !== 9 || isLoadingData}
                                        >
                                          {t(step.text)}
                                        </button>
                                      </div>
                                    );
                                  }

                                  if (step.type == 'select') {
                                    const options = step.options.map((el, i) => ({
                                      label: t(el.label),
                                      value: el.value,
                                    }));
                                    return (
                                      <>
                                        <div
                                          key={`button-step-${stepIndex}`}
                                          className="modal_container__body__inputs align-items-start"
                                        >
                                          <label htmlFor={step.id}>{t(step.text)}</label>
                                          <CustomSelect
                                            variant="modal-edit-data"
                                            options={step.options}
                                            value={selectedItem}
                                            formatCreateLabel={(i) => i}
                                            placeholder={t('pleaseSelectHere')}
                                            onChange={(e) => setSelectedItem(e)}
                                            isClearable={true}
                                            toTranslate={true}
                                            fullWidth={true}
                                          />
                                        </div>
                                        {(selectedItem?.value === 'termination_claim_customer' ||
                                          selectedItem?.value === 'termination_claim_insurer' ||
                                          selectedItem?.value === 'withdrawal_by_the_insurer') &&
                                          <div
                                            key={`text-step-${stepIndex}`}
                                            className="modal_container__body__inputs align-items-start"
                                          >
                                            <label htmlFor={step.id}>{t(step.text)}</label>
                                            <input
                                              id={`input-claim-${step.id}`}
                                              className="modal_container__body__input w-100"
                                              placeholder={t(claimNo)}
                                              // required={step.required || false}
                                              // disabled={step.disabled || false}
                                              onChange={(e) => setClaimNo(e.target.value)
                                              }
                                              value={claimNo}
                                            />
                                          </div>}
                                      </>
                                    );
                                  }

                                  if (
                                    (step.type == 'date' && !step.dependency) ||
                                    (step.dependency && step.dependency == selectedItem?.value)
                                  ) {
                                    return (
                                      <>
                                        <div
                                          key={`button-step-${stepIndex}`}
                                          className="modal_container__body__inputs align-items-start w-100 "
                                        >
                                          <label htmlFor={step.id}>{t(step.text)}</label>
                                          <div className="d-flex gap-3 w-100">
                                            <CustomSelect
                                              variant="modal-edit-data"
                                              options={generateDays(
                                                years.value,
                                                months.value
                                              )}
                                              formatCreateLabel={(i) => i}
                                              menuPlacement={'top'}
                                              placeholder={t('DD')}
                                              value={days}
                                              onChange={(e) => setDays(e)}
                                              isClearable={false}
                                              toTranslate={false}
                                            />
                                            <CustomSelect
                                              variant="modal-edit-data"
                                              options={monthsOptions}
                                              formatCreateLabel={(i) => i}
                                              placeholder={t('MM')}
                                              value={months}
                                              menuPlacement={'top'}
                                              onChange={(e) => setMonths(e)}
                                              isClearable={false}
                                              toTranslate={false}
                                            />
                                            <CustomSelect
                                              variant="modal-edit-data"
                                              options={generateYears(
                                                moment().add(10, 'year').format('YYYY')
                                              )}
                                              formatCreateLabel={(i) => i}
                                              placeholder={t('YY')}
                                              value={years}
                                              menuPlacement={'top'}
                                              onChange={(e) => setYears(e)}
                                              isClearable={false}
                                              toTranslate={false}
                                            />
                                          </div>
                                        </div>
                                      </>
                                    );
                                  }

                                  if (
                                    (step.type == 'text' && !step.dependency) ||
                                    (step.dependency && step.dependency == selectedItem.value)
                                  ) {
                                    return (
                                      <>
                                        <div
                                          key={`button-step-${stepIndex}`}
                                          className="modal_container__body__inputs align-items-start w-100 "
                                        >
                                          <label htmlFor={step.id}>{t(step.text)}</label>
                                          <div className="d-flex gap-3 w-100">
                                            <span style={step.style}>
                                              {getTerminationDate}
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  }
                                }
                                return null; // Ignore non-object steps or steps without a type
                              }
                            )}

                            {/* {section.externalProcesses &&
                              selectedItem != '' &&
                              Object.values(
                                Object.values(section.externalProcesses).find(
                                  (el) => el.title == selectedItem.value
                                ).steps
                              ).map((el, index) => {
                                if (index == activeStep) {
                                  return (
                                    <div key={el.id}>
                                      <div
                                        className="modal_container__body__text"
                                        style={{
                                          maxWidth: 'unset',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                        }}
                                      >
                                        <span>{t(el.text)}</span>
                                      </div>

                                      {el.type == 'text' && (
                                        <>
                                          <div
                                            className="modal_container__body__inputs"
                                            style={{
                                              maxWidth: 'unset',
                                              justifyContent: 'flex-start',
                                              display: 'flex',
                                            }}
                                          >
                                            <input
                                              className="modal_container__body__input"
                                              placeholder={t(el.placeholder)}
                                              value={
                                                el.id == 'name'
                                                  ? petName
                                                  : el.id == 'chipNumber'
                                                    ? petChip
                                                    : ''
                                              }
                                              onChange={(e) => {
                                                el.id == 'name' && setPetName(e.target.value);
                                                el.id == 'chipNumber' && handleChip(e);
                                              }}
                                            />
                                            {chipError && (
                                              <div className="modal_container__body__error FastShow">
                                                {t('chipWrong')}
                                              </div>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  );
                                }
                              })} */}
                          </>)}

                      {(section.formType === 'result' && selectedPet && !isLoadingData) &&
                        <>
                          {isUpdatingData
                            ? <Loading noText fast width={'150px'} scroll={false} />
                            : <button
                              onClick={() => { terminateContract() }}
                              className={`button_red_small__admin `}
                              style={{ marginBottom: '10px' }}
                              disabled={!selectedItem || !days || !months || !years}
                            >
                              {t('Bestätigen')}
                            </button>}
                        </>}
                    </div>
                  )
                  : null}
              </>
            )
          })}

      {errorMessage &&
        <span style={{
          color: '#FE4B4E',
          fontSize: '34px',
          fontWeight: '800',
        }}>
          {errorMessage}
        </span>}

    </div>
  );
};

export default CancellationOrRevocation;
