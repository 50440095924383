import { CHANGE_PET_DATA_FLOW } from './Flow';
import moment from 'moment';


const CHANGE_EMAIL_BIRTHDAY_FLOW = [
  {
    formType: 'search',
    components: {
      pleaseEnterPetName: {
        type: 'text_input',
        text: 'E-Mail-Adresse',
        placeholder: 'E-Mail-Adresse',
        id: 'email',
        required: true,
      },
      submit: {
        type: 'button',
        text: 'Ok',
        btnType: 'submit',
        id: 'submit',
      },
    },
  },
  {
    id: 'change_form',
    dependency: 'email_form',
    components: {
      s: {
        dependency: 'email',
        type: 'select',
        text: 'Datenänderung',
        placeholder: '',
        id: 'select_01',
        options: [
          { label: 'E-Mail-Adresse', value: 'email' },
          { label: 'Geburtstag', value: 'date' },
        ],
        required: true,
      },
      new_email: {
        dependency: 'email',
        type: 'text_input',
        text: 'Neue E-Mail-Adresse',
        placeholder: 'Neue E-Mail-Adresse',
        id: 'new_email',
      },
      date: {
        dependency: 'date',
        type: 'date',
        text: 'Neues Geburtsdatum',
        placeholder: 'Neues Geburtsdatum',
        id: 'date',
      },
    },
  },
];

const CHANGE_CONTRACT_FLOW = [
  {
    formType: 'search',
    components: {
      policyNo: {
        type: 'text_input',
        text: 'policyNo',
        placeholder: 'policyNo',
        id: 'policyNo',
        required: true,
      },
      pleaseEnterPetName: {
        type: 'text_input',
        text: 'confirmPet',
        placeholder: 'confirmPet',
        id: 'policyNo',
        required: true,
        disabled: true,
      },
      submit: {
        type: 'button',
        text: 'Ok',
        btnType: 'submit',
        id: 'submit',
      },
    },
  },
  {
    formType: 'result',
    id: 'change_form',
    dependency: 'email_form',
    components: {
      select: {
        type: 'radio-options',
        text: 'What_would_you_like_to_do',
        placeholder: 'What_would_you_like_to_do',
        id: 'select',
        direction: 'vertical',
        options: [
          {
            label: 'Change_deductible',
            value: 'Change_deductible',
          },
          {
            label: 'change_tariff',
            value: 'change_tariff',
          },
        ],
      },
      tariffs: {
        type: 'radio-options',
        dependency: "change_tariff",
        text: 'pleaseSelectTariff',
        placeholder: 'pleaseSelectTariff',
        id: 'tariff',
        direction: 'horizontal',
        options: [
          {
            label: 'OP Basic',
            value: 'OP Basic',
            percentage: [
              {
                label: '100%',
                value: '1',
              },
              {
                label: '80%',
                value: '0.8',
              },
              {
                label: '70%',
                value: '0.7',
              },
            ]
          },
          {
            label: 'Health Basic',
            value: 'Health Basic',
            percentage: [
              {
                label: '100%',
                value: '1',
              },
              {
                label: '80%',
                value: '0.8',
              },

            ]
          },
          {
            label: 'OP Comfort',
            value: 'OP Comfort',
            percentage: [
              {
                label: '100%',
                value: '1',
              },
              {
                label: '80%',
                value: '0.8',
              },

            ]
          },
          {
            label: 'Health Comfort',
            value: 'Health Comfort',
            percentage: [
              {
                label: '100%',
                value: '1',
              },
              {
                label: '80%',
                value: '0.8',
              },

            ]

          },
          {
            label: 'OP Full',
            value: 'OP Full',
            percentage: [
              {
                label: '100%',
                value: '1',
              },

            ]
          },
          {
            label: 'Health Full',
            value: 'Health Full',
            percentage: [
              {
                label: '100%',
                value: '1',
              },

            ]
          },
        ],
      },
      percent_select: {
        type: 'radio-options',
        // dependency: "Change_deductible",
        text: 'changeDeductibleTo',
        placeholder: 'changeDeductibleTo',
        id: 'percent',
        direction: 'horizontal',
        options: [
          {
            label: '70%',
            value: '0.7',
          },
          {
            label: '80%',
            value: '0.8',
          },
          {
            label: '100%',
            value: '1',
          },
        ],
      },

      amount: {
        type: 'text',
        text: 'The new premium amounts to',
        isValueDynamic: true,
        id: 'amount',
        initialValue: '00,00',
        style: {
          color: '#FE4B4E',
          fontSize: '34px',
          fontWeight: '800',
          fontVariantNumeric: 'lining-nums',
        },
      },
      date: {
        type: 'date',
        text: 'When should the change be active?',
        placeholder: 'When should the change be active?',
        id: 'date',
      },
    },
  },
];

const REACTIVATE_FLOW = [
  {
    formType: 'search',
    components: {
      policyNo: {
        type: 'text_input',
        text: 'policyNo',
        placeholder: 'policyNo',
        id: 'policyNo',
        required: true,
      },
      pleaseEnterPetName: {
        type: 'text_input',
        text: 'confirmPet',
        placeholder: 'confirmPet',
        id: 'policyNo',
        required: true,
        disabled: true,
      },
      submit: {
        type: 'button',
        text: 'Ok',
        btnType: 'submit',
        id: 'submit',
      },
    },
  },
  {
    formType: 'result',
    id: 'change_form',
    dependency: 'email_form',
    components: {
      date: {
        type: 'date',
        text: 'date_on_contract_is_to_be_reactivated',
        placeholder: 'date_on_contract_is_to_be_reactivated',
        id: 'date',
      },
    },
  },
];

const options = Object.values(CHANGE_PET_DATA_FLOW).map((el) => ({
  label: el.title,
  value: el.title,
  ...el,
}));

const ADMIN_PET_DATA_CHANGE_FLOW = [
  {
    formType: 'search',
    components: {
      Chipnummer: {
        type: 'text_input',
        text: 'chipNumber',
        placeholder: 'chipNumber',
        id: 'Chipnummer',
        required: true,
      },
      pleaseEnterPetName: {
        type: 'text_input',
        text: 'confirmPet',
        placeholder: 'confirmPet',
        id: 'confirmPet',
        required: true,
        disabled: true,
      },
      submit: {
        type: 'button',
        text: 'Ok',
        btnType: 'submit',
        id: 'submit',
      },
    },
  },
  {
    id: 'change_select',
    dependency: 'email_form',
    components: {
      s: {
        dependency: 'email',
        type: 'select',
        text: 'Datenänderung',
        placeholder: '',
        id: 'select_01',
        options: options,
        required: true,
      },
    },
  },
  {
    id: 'change_form',
    dependency: 'change_select',
    externalProcesses: CHANGE_PET_DATA_FLOW || {},
    components: {},
  },
];

const CANCELLATION_OR_REVOCATION_FLOW = [
  {
    formType: 'search',
    components: {
      policyNo: {
        type: 'text_input',
        text: 'policyNo',
        placeholder: 'policyNo',
        id: 'policyNo',
        required: true,
      },
      pleaseEnterPetName: {
        type: 'text_input',
        text: 'confirmPet',
        placeholder: 'confirmPet',
        id: 'policyNo',
        required: true,
        disabled: true,
      },
      submit: {
        type: 'button',
        text: 'Ok',
        btnType: 'submit',
        id: 'submit',
      },
    },
  },
  {
    formType: 'result',
    id: 'change_form',
    dependency: 'email_form',
    components: {
      s: {
        dependency: 'email',
        type: 'select',
        text: 'selectOption',
        placeholder: 'selectOption',
        id: 'select_01',
        options: [
          {
            label: 'revocation',
            value: 'revocation',
          },
          {
            label: 'ordinary_cancelation',
            value: 'ordinary_cancelation',
          },
          {
            label: 'cancellation_for_other_reasons',
            value: 'cancellation_for_other_reasons',
          },
          {
            label: 'termination_claim_customer',
            value: 'termination_claim_customer',
          },
          {
            label: 'ordinary_cancelation_insurer',
            value: 'ordinary_cancelation_insurer',
          },
          {
            label: 'termination_claim_insurer',
            value: 'termination_claim_insurer',
          },
          {
            label: 'withdrawal_by_the_insurer',
            value: 'withdrawal_by_the_insurer',
          },
        ],
        required: true,
      },
      claimNo: {
        type: 'text_input',
        text: 'claimNo',
        id: 'ClaimNO',
        dependencies: ['withdrawal_by_the_insurer', 'termination_claim_customer', 'ordinary_cancelation_insurer']
      },
      date: {
        type: 'date',
        text: 'Implementation_date',
        placeholder: 'Neues Geburtsdatum',
        id: 'date',
      },
      amount: {
        type: 'text',
        text: 'The contract is terminated as of the following date',
        isValueDynamic: true,
        id: 'notification_date',
        initialValue: `${String(moment().format('DD'))}.${String(moment().format('MM'))}.${String(new Date().getFullYear())}`,
        style: {
          color: '#FE4B4E',
          fontSize: '34px',
          fontWeight: '800',
          fontVariantNumeric: 'lining-nums',
        },
      },
    },
  },
];

export {
  CHANGE_EMAIL_BIRTHDAY_FLOW,
  CHANGE_CONTRACT_FLOW,
  REACTIVATE_FLOW,
  ADMIN_PET_DATA_CHANGE_FLOW,
  CANCELLATION_OR_REVOCATION_FLOW,
};
