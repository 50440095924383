import { getToken } from './token';
import configureStore from './store';
import { showToast } from './helpers';
import i18n from 'i18next';

const { persistor } = configureStore();

export const Request = ({ method, values, route, token, formData }) => {
  
  let content = {};
  if (formData) {
    content = {
      method: method.toUpperCase(),
      headers: { Accept: 'application/json, text/plain, */*' },
      body: formData,
    };
  } else {
    content = {
      method: method.toUpperCase(),
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      contentType: 'application/json; charset=utf-8',
    };
    if (values) {
      content['body'] = JSON.stringify(values);
      content['dataType'] = 'json';
    }
  }

  if (token) {
    content['headers']['Authorization'] = `Bearer ${JSON.parse(token).token}`;
  } else {
    if (getToken()) {
      content['headers']['Authorization'] =
        `Bearer ${JSON.parse(getToken()).token}`;
    }
  }

  const isAbsoluteUrl = /^(http|https):\/\//.test(route);

  return fetch(isAbsoluteUrl ? route : '/' + route, content)
    .then(async (res) => {
      if (res.status === 400) {
        const errorDetails = await res.json();
        if (errorDetails.message) showToast(i18n.t(errorDetails.message), { error: true });
      }

      if (res.status === 401) {
        localStorage.clear();
        persistor.purge().then(() => {
          window.location.reload();
        });
      }

      if (!res.ok) {
        throw new Error(`Request failed with status ${res.status}`);
      }

      return res;
    })
    .catch((error, res) => {
      console.error('Fetch error:', error);
      throw error;
    });
};
